import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Avatar,
  Card,
  CardContent,
  Input,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import './ProfileAccount.css';

const ProfileAccount = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [userProfile, setUserProfile] = useState({
    email: "",
    username: "",
    age: "",
    diabetes_type: "",
  });
  const [profileImage, setProfileImage] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      setUserProfile(currentUser);
      const storedImage = localStorage.getItem('profileImage');
      if (storedImage) {
        setProfileImage(storedImage);
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    localStorage.setItem('currentUser', JSON.stringify(userProfile));
    const userProfiles = JSON.parse(localStorage.getItem('userProfiles')) || [];
    const updatedProfiles = userProfiles.map(profile => 
      profile.id === userProfile.id ? userProfile : profile
    );
    localStorage.setItem('userProfiles', JSON.stringify(updatedProfiles));
    console.log("Saving profile:", userProfile);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const compressImage = (file, maxWidth, maxHeight, quality) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = canvasRef.current;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL("image/png", quality));
        };
      };
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/png") {
      try {
        const compressedImage = await compressImage(file, 300, 300, 0.7);
        setProfileImage(compressedImage);
        localStorage.setItem('profileImage', compressedImage);
      } catch (error) {
        console.error("Error compressing image:", error);
        alert("Error processing image. Please try a smaller image.");
      }
    } else {
      alert("Please select a PNG image.");
    }
  };

  return (
    <Card className="profile-card">
      <Typography variant="h5" gutterBottom align="left">
        User Profile
      </Typography>
      <CardContent className="profile-content">
        <Box className="profile-info">
          <Box className="profile-fields">
            <Typography variant="body1" gutterBottom>
              Email Address
            </Typography>
            <TextField
              name="email"
              value={userProfile.email || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              disabled={!isEditing}
            />
            <Typography variant="body1" gutterBottom>
              Username
            </Typography>
            <TextField
              name="username"
              value={userProfile.username || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              disabled={!isEditing}
            />
            <Typography variant="body1" gutterBottom>
              Age
            </Typography>
            <TextField
              name="age"
              value={userProfile.age || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              disabled={!isEditing}
            />
            <Typography variant="body1" gutterBottom>
              Diabetes Type
            </Typography>
            <TextField
              name="diabetes_type"
              value={userProfile.diabetes_type || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              disabled={!isEditing}
            />
          </Box>
          <Avatar 
            className="profile-avatar" 
            src={profileImage}
            alt={userProfile.username}
          >
            {!profileImage && userProfile.username ? userProfile.username[0].toUpperCase() : 'U'}
          </Avatar>
        </Box>
        {isEditing && (
          <Input
            type="file"
            inputProps={{ accept: "image/png" }}
            onChange={handleImageChange}
          />
        )}
        <Box className="profile-action">
          <Button
            variant="contained"
            onClick={isEditing ? handleSave : handleEdit}
          >
            {isEditing ? "SAVE" : "EDIT"}
          </Button>
        </Box>
      </CardContent>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </Card>
  );
};

export default ProfileAccount;

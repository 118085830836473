import React, { useEffect, useState } from 'react';
import {Alert, Box, Dialog, DialogTitle, DialogContent, DialogContentText, Typography} from "@mui/material";
import { AccessAlarm } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

const Notification = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [collection, setCollection] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
          const events = localStorage.getItem("events");
          if (events) {
            setCollection(JSON.parse(events).reverse());
          }
      } catch (error) {
        console.error("Error checking auth status:", error);
      }
    };
    checkAuth();
  }, []);

  const names = { 'Medication': 'primary', 'Blood Test': 'error', 'Exercise': 'warning' };
  const colors = { 'Medication': 'blue', 'Blood Test': 'red', 'Exercise': 'yellow' };
  const fr = { float: 'right' };
  const m10 = { margin: 10 };

  const getHour = (date) => {
    date = new Date(date);
    if (isNaN(date.getTime())) {
      return 'Invalid Time';
    }
    const hours = date.getHours();
    const ampm = hours < 12 ? 'am' : 'pm';
    return `${hours} ${ampm}`;
  };

  const handleNotificationClick = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (selectedEvent) {
      navigate(`/calendar/${selectedEvent.id}`);
    }
  };

  return (
    <Box m="20px">
      {collection && collection.length>0?(
        collection.map((item, index) => (
          <Alert
            key={index}
            icon={<AccessAlarm fontSize="inherit" />}
            style={{...m10, backgroundColor: colors[item.title], color: 'white'}}
            variant="filled"
            severity={names[item.title]}
            action={<div style={fr}>{getHour(item.start)}</div>}
            onClick={() => handleNotificationClick(item)}
          >
            Time for your scheduled {item.title}
          </Alert>
        ))
      ):
          (
              <Typography variant="h6" color="textSecondary" align="center" style={{ margin: '20px' }}>No Data Available</Typography>
          )
      }
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedEvent ? selectedEvent.title : "Event Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedEvent ? `Details for event: ${selectedEvent.title}` : "No event selected."}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Notification;

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from "@mui/material";
const AdditionalInfoForm = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, email, username } = location.state || {};

  const [age, setAge] = useState('');
  const [diabetesType, setDiabetesType] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let userProfiles = JSON.parse(localStorage.getItem('userProfiles')) || [];
      userProfiles = userProfiles.filter(function (u) {
        return u.email !== email;
      });
      userProfiles.push({email: email, username: username, age: age, diabetes_type: diabetesType});
      setIsAuthenticated(true);
      navigate("/home");
    } catch (error) {
      console.error("Error saving additional info:", error);
    }
  };

  return (
    <Box className="additional-info-section">
      <form onSubmit={handleSubmit}>
        <Typography variant="h2">Additional Information</Typography>
        <Typography>Please provide some additional details to complete your profile</Typography>
        <TextField
          label="Age"
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Diabetes Type"
          type="text"
          value={diabetesType}
          onChange={(e) => setDiabetesType(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Complete Profile
        </Button>
      </form>
    </Box>
  );
};

export default AdditionalInfoForm;

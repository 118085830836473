import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SubjectIcon from "@mui/icons-material/Subject";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const ScheduleDialog = ({ open, setOpen, row }) => {
	const names = ["Medication", "Blood Test", "Exercise"];
	const selectColors = {
		Medication: "#a564d2",
		"Blood Test": "#e54141",
		Exercise: "#f2b722",
	};
	const selectStyle = {
		width: "30%",
		height: "40px",
		border: "1px solid #c5bfbf",
		borderRadius: "10px",
		paddingLeft: "10px",
	};
	const [showTip, setShowTip] = useState(false);
	const [btnIndex, setBtnIndex] = useState(-1);
	const [btnStyle, setBtnStyle] = useState(-1);
	const btnStyles = [
		{ backgroundColor: "#a564d2", color: "#ffffff" },
		{ backgroundColor: "#e54141", color: "#ffffff" },
		{ backgroundColor: "#f2b722", color: "#ffffff" },
	];

	const [date, setDate] = useState(dayjs(new Date()));
	const dateChange = (newValue) => {
		setDate(newValue);
	};
	useEffect(() => {
		if (row && row.title) {
			const index = names.indexOf(row.title);
			setBtnIndex(index);
			setBtnStyle(btnStyles[index]);
		} else {
			setBtnIndex(-1);
			setBtnStyle({});
		}
	}, [row]);
	const repeats = ["No Repeat", "Daily", "Weekly"];
	const handleClose = () => {
		setOpen(false);
	};

	const mg = {
		marginTop: 10,
	};
	const handleCloseTip = () => {
		setShowTip(false);
	};
	const changeType = (e) => {
		const index = names.indexOf(e.target.innerText);
		setBtnIndex(index);
		setBtnStyle(btnStyles[index]);
	};
	const save = (item) => {
		let collection = localStorage.getItem("events");
		if (!collection) {
			collection = [];
		} else {
			collection = JSON.parse(collection);
		}
		if (item.repeat === "Daily") {
			let startDate = new Date(item.start);
			for (let i = 0; i < 30; i++) {
				let newEvent = Object.assign({}, item);
				let date = new Date(startDate);
				date.setDate(startDate.getDate() + i);
				newEvent.id = newEvent.id + i * 1000;
				newEvent.start = date;
				newEvent.end = date;
				collection.push(newEvent);
			}
		} else if (item.repeat === "Weekly") {
			let startDate = new Date(item.start);
			for (let i = 0; i < 4; i++) {
				let currentDate = new Date(startDate);
				let newEvent = Object.assign({}, item);
				currentDate.setDate(currentDate.getDate() + i * 7);
				newEvent.id = newEvent.id + i * 1000;
				newEvent.start = currentDate;
				newEvent.end = newEvent.start;
				collection.push(newEvent);
			}
		} else {
			collection.push(item);
		}
		localStorage.setItem("events", JSON.stringify(collection));
		window.location.reload();
	};
	return (
		<Fragment>
			<Dialog open={showTip} onClose={handleCloseTip}>
				<DialogTitle id="alert-dialog-title">Warning</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Please complete all required information.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseTip} autoFocus>
						ok
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth
				open={open}
				onClose={handleClose}
				PaperProps={{
					component: "form",
					onSubmit: (event) => {
						event.preventDefault();
						const formData = new FormData(event.currentTarget);
						const formJson = Object.fromEntries(formData.entries());
						if (btnIndex === -1 || !date || !date.$d || !formJson.tip) {
							setShowTip(true);
							return;
						}
						formJson.title = names[btnIndex];
						formJson.start = date.$d;
						formJson.end = date.$d;
						formJson.value = "";
						formJson.color = selectColors[names[btnIndex]];
						if(row.id){
							let collection = localStorage.getItem("events");
							let events = collection ? JSON.parse(collection) : [];
							const index = events.findIndex(e => parseInt(e.id) === parseInt(row.id));
							formJson.value=events[index].value;
							formJson.id=row.id;
							formJson.color=row.color;
							events[index] = formJson;
							localStorage.setItem("events", JSON.stringify(events));
						}else {
							formJson.id = new Date().getTime();
							save(formJson);
						}
						handleClose();
					},
				}}
			>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={1}></Grid>
						<Grid item xs={11}>
							<TextField
								autoFocus
								required
								margin="dense"
								id="medicineName"
								name="medicineName"
								type="text"
								label="Medicine Name"
								fullWidth
								variant="standard"
								defaultValue={row.medicineName}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={1}></Grid>
						{names.map((name, index) => (
							<Grid item xs={2} key={index}>
								<Chip
									style={btnIndex === index ? btnStyle : {}}
									label={name}
									variant={row.title === name ? "" : "outlined"}
									onClick={changeType}
								/>
							</Grid>
						))}
					</Grid>
					<Grid container spacing={2} style={mg}>
						<Grid item xs={1}>
							<AccessTimeIcon />
						</Grid>
						<Grid item xs={11} style={{ fontSize: 18 }}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateTimePicker
									label="Date&Time picker"
									defaultValue={row.start}
									onChange={dateChange}
									renderInput={(params) => <TextField {...params} />}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={1}></Grid>
						<Grid item xs={11}>
							<RadioGroup
								row
								defaultValue={row.tip}
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="tip"
							>
								<FormControlLabel
									value="Before Meal"
									control={<Radio />}
									label="Before Meal"
									sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
								/>
								<FormControlLabel
									value="After Meal"
									control={<Radio />}
									label="After Meal"
									sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
								/>
								<FormControlLabel
									value="Before Sleep"
									control={<Radio />}
									label="Before Sleep"
									sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
								/>
							</RadioGroup>
						</Grid>
					</Grid>
					<Grid container spacing={2} style={mg}>
						<Grid item xs={1} style={mg}>
							<SyncAltIcon />
						</Grid>
						<Grid item xs={11}>
							<select
								id="scheduleSelect"
								defaultValue={row.repeat}
								style={selectStyle}
								name="repeat"
							>
								<option disabled value="">
									Repeat Frequency
								</option>
								{repeats.map((name, index) => (
									<option key={index} value={name}>
										{name}
									</option>
								))}
							</select>
						</Grid>
					</Grid>
					<Grid container spacing={2} style={mg}>
						<Grid item xs={1} style={mg}>
							<SubjectIcon />
						</Grid>
						<Grid item xs={11}>
							<TextField
								autoFocus
								required
								margin="dense"
								id="description"
								name="description"
								label="Add description"
								type="text"
								fullWidth
								variant="standard"
								defaultValue={row.description}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						type="submit"
						style={{ backgroundColor: "#a564d2" }}
						size="large"
						variant="contained"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default ScheduleDialog;

import React from 'react';
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();

  return (
    <Box 
      m="20px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
      bgcolor="#f7f0ff"
      textAlign="left"
      p="20px"
    >
      {/* Top section for Fearless */}
      <Box>
        <Typography 
          variant="h4" 
          component="div" 
          gutterBottom
          sx={{ fontSize: '24px' }}
        >
          Fearless 
        </Typography>
      </Box>
      
      {/* Bottom section for the rest of the content */}
      <Box 
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        height="100%"
        sx={{ marginLeft: '10%', marginRight: 'auto' }} 
      >
        <Typography 
          variant="h1" 
          component="h1" 
          fontWeight="bold" 
          mb={2}
          sx={{ fontSize: '48px' }}
        >
          Health Tracking Made Simple
        </Typography>
        <Typography 
          variant="body1" 
          mb={4} 
          maxWidth="600px"
          sx={{ fontSize: '20px' }}
        >
          Welcome to Health Tracking Made Simple, a tool designed to help seniors manage
          diabetes with ease and confidence. Our platform offers tools to track exercise, medication,
          and blood sugar levels, with personalized exercise recommendations and a detailed health calendar.
          We make diabetes management simple and effective, empowering seniors to live healthier, 
          happier lives. 
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          sx={{ fontSize: '18px' }} 
          onClick={() => navigate("/login")}
        >
          Sign in
        </Button>
      </Box>
    </Box>
  );
};

export default Home;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, CircularProgress } from "@mui/material";
import '../../index.css';
import './LoginSignUp.css';

const SignUp = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
    age: "",
    diabetes_type: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Invalid email address";
    }
    if (isNaN(formData.age) || formData.age === "") {
      tempErrors.age = "Age must be a numeric value";
    }
    if (isNaN(formData.diabetes_type) || formData.diabetes_type === "") {
      tempErrors.diabetes_type = "Types of Diabetes must be a numeric value";
    }
    if (formData.diabetes_type !== "1" && formData.diabetes_type !== "2") {
      tempErrors.diabetes_type = "Type of diabetes must be either 1 or 2";
    }
    if (formData.password !== formData.password_confirmation) {
      tempErrors.password_confirmation = "Passwords do not match";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const [signupSuccess, setSignupSuccess] = useState(false);

const handleSignUp = async (event) => {
  event.preventDefault();
  if (validateForm()) {
    setIsLoading(true);
    try {
      const userProfiles = JSON.parse(localStorage.getItem('userProfiles')) || [];
      let user = userProfiles.find(u => u.email === formData.email);
      if(user){
        setErrors({ submit: "Email already exists." });
        return;
      }
      user = userProfiles.find(u => u.username === formData.username);
      if(user){
        setErrors({ submit: "Username already exists." });
        return;
      }
      userProfiles.push(formData);
      localStorage.setItem("userProfiles", JSON.stringify(userProfiles));
      setSignupSuccess(true);
    } catch (error) {
      console.error("Error signing up:", error);
      setErrors({ submit: error.message });
    } finally {
      setIsLoading(false);
    }
  }
};

  if (signupSuccess) {
  return (
    <Box className="signup-section">
      <Typography variant="h6">
        Account created successfully! Please try to log in using your newly created account details.
      </Typography>
      <Button 
        variant="contained" 
        onClick={() => navigate("/login")} 
        style={{ marginTop: '20px' }}
      >
        Go to Login
      </Button>
    </Box>
  );
}

  return (
    <Box className="signup-section">
      <form className="signup-form" onSubmit={handleSignUp}>
        <Typography variant="h2">Sign up</Typography>
        <Typography>Welcome! Please enter your details</Typography>
        <TextField
          label="Email"
          type="email"
          name="email"
          required
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="User name"
          type="text"
          name="username"
          required
          fullWidth
          margin="normal"
          value={formData.username}
          onChange={handleChange}
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          required
          fullWidth
          margin="normal"
          value={formData.password}
          onChange={handleChange}
        />
        <TextField
          label="Password confirmation"
          type="password"
          name="password_confirmation"
          required
          fullWidth
          margin="normal"
          value={formData.password_confirmation}
          onChange={handleChange}
          error={!!errors.password_confirmation}
          helperText={errors.password_confirmation}
        />
        <TextField
          label="Age"
          type="text"
          name="age"
          required
          fullWidth
          margin="normal"
          value={formData.age}
          onChange={handleChange}
          error={!!errors.age}
          helperText={errors.age}
        />
        <TextField
          label="Types of diabetes"
          type="text"
          name="diabetes_type"
          required
          fullWidth
          margin="normal"
          value={formData.diabetes_type}
          onChange={handleChange}
          error={!!errors.diabetes_type}
          helperText={errors.diabetes_type}
          inputProps={{ min: 1, max: 2}}
        />
        {errors.submit && <Typography color="error">{errors.submit}</Typography>}
        <Box className="form-actions">
          <Button
            variant="contained" 
            type="submit" 
            className="submit-signup"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Sign up"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SignUp;

import React, { useState } from "react";
import { Box, Button, TextField, Typography, Link, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordReset } from "../../firebase"; 
import './LoginSignUp.css';

const Login = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const navigate = useNavigate();
  
    const handleLogin = async (event) => {
      event.preventDefault();
      setIsLoading(true);
      setError("");
      try {
        const userProfiles = JSON.parse(localStorage.getItem('userProfiles')) || [];
        const user = userProfiles.find(u => u.email === email);
        if (user && user.password === password) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          setIsAuthenticated(true);
          navigate("/calendar");
        } else {
          throw new Error("Invalid email or password");
        }
      } catch (err) {
        console.error("Login error:", err);
        setError("Invalid email or password. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleForgotPassword = (event) => {
      event.preventDefault(); 
      setOpenResetDialog(true);
    };
  
    const handleCloseResetDialog = () => {
      setOpenResetDialog(false);
      setResetEmail("");
    };
  
    const handleSendResetEmail = async () => {
      if (!resetEmail) {
        setError("Please enter your email address.");
        return;
      }
      setIsLoading(true);
      setError("");
      try {
        const result = await sendPasswordReset(resetEmail);
        if (result.success) {
          alert("Password reset email sent. Please check your inbox.");
          handleCloseResetDialog();
        } else {
          setError(result.message);
        }
      } catch (error) {
        console.error("Error sending password reset email:", error);
        setError("Failed to send password reset email. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <Box className="signup-section">
        <Typography variant="h1">Sign in</Typography>
        <Typography>Welcome back! Please enter your details</Typography>
        {error && <Typography color="error" className="error-message">{error}</Typography>}
        <form className="signup-form" onSubmit={handleLogin}>
          <TextField
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
         <Box 
  component="button"
  onClick={handleForgotPassword}
  sx={{
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: '0.5rem 1rem',
    border: '1px solid #9c27b0',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: '#9c27b0',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(156, 39, 176, 0.1)',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 0 2px rgba(156, 39, 176, 0.5)',
    },
  }}
>
  Forgot Password?
</Box>
          <Box className="form-actions" mt={2}>
            <Button
              variant="contained"
              type="submit"
              className="submit-signup"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Sign in"}
            </Button>
          </Box>
        </form>
        <Typography mt={2}>
          Don't have an account?{" "}
          <Link className="link-text" onClick={() => navigate("/signup")}>
            Sign up
          </Link>
        </Typography>
  
        <Dialog open={openResetDialog} onClose={handleCloseResetDialog}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To reset your password, please enter your email address here. We will send you a password reset link.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="resetEmail"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseResetDialog}>Cancel</Button>
            <Button onClick={handleSendResetEmail}>Send Reset Link</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };
  
  export default Login;
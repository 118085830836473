import React, { useState } from 'react';
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled('div')({
  display: 'flex',
  height: '100vh',
  backgroundColor: '#f9f9f9',
});

const Content = styled('div')({
  flex: 1,
  padding: '40px',
  backgroundColor: '#fff',
  boxShadow: '0 0 15px rgba(0,0,0,0.1)',
  borderRadius: '10px',
  margin: 'auto',
  maxWidth: '600px',
  position: 'relative',
});

const StyledButton = styled(Button)({
  backgroundColor: '#a27bff',
  color: '#fff',
  textTransform: 'none',
  position: 'absolute',
  right: '10px',
  bottom: '20px',
  '&:hover': {
    backgroundColor: '#9366ff',
  },
});

// Create restart button 
const RestartButton = styled(Button)({
  backgroundColor: '#a27bff',
  color: '#fff',
  textTransform: 'none',
  position: 'margin',
  left: '475px',
  top: '10px',
  '&:hover': {
    backgroundColor: '#9366ff',
  },
});


const Exercise = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [value, setValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showVideos, setShowVideos] = useState(false);

  const questions = [
    {
      question: 'What kind of workout would you want today?',
      options: ['Cardio', 'Strength training', 'Flexibility training', 'Balance and stability (yoga)', 'Dance'],
    },
    {
      question: 'What level of workout would you like today?',
      options: ['Beginner', 'Intermediate', 'Advanced'],
    },
    {
      question: 'Do you prefer shorter or longer videos?',
      options: ['Short(10-15 minutes)', 'Long'],
    },
  ];

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setSelectedOptions([...selectedOptions, value]);
      setCurrentQuestion(currentQuestion + 1);
      setValue('');
    } else {
      setSelectedOptions([...selectedOptions, value]);
      setShowVideos(true);
    }
  };

  // Create restart button
  const handleRestart = () => {
    setCurrentQuestion(0);
    setValue('');
    setSelectedOptions([]);
    setShowVideos(false);
  };

  const progressValue = ((currentQuestion + 1) / questions.length) * 100;

  const VideoContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '20px',
  });

  // combination of videos 
  const renderVideos = () => {
    if (selectedOptions.includes('Cardio') && selectedOptions.includes('Beginner') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/kA8EpHvrt68?si=JItcf7nKZmEka45Y" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/Ev6yE55kYGw?si=2TSWBqepBcrsUAYF" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Cardio') && selectedOptions.includes('Beginner') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/PvEnWsPrL4w?si=ts1UD9TrSQhtBMsJ" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/9kraLB-Nxjs?si=cFNciCPXzS3HLOp2" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Cardio') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/gT9WO94knPg?si=igejH-o9kED5WuJR" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Cardio') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/DIET8goe1AU?si=vCWWi5CQzCtq9hE7" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Cardio') && selectedOptions.includes('Advanced') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/9_gI-yOf2yM?si=w1jNR7yVftYqKpga" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Cardio') && selectedOptions.includes('Advanced') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/gC_L9qAHVJ8?si=WRCOV3Q02s6wVo9N" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Strength training') && selectedOptions.includes('Beginner') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/ZjznZViB8_M?si=y0EAuJMiNPPjdeas" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/cTuSMe4Q_5Q?si=O17ICrTpt44czUG7" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Strength training') && selectedOptions.includes('Beginner') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/slBsNXH4GW8?si=XSQEqWaCrw1Wl4sF" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Strength training') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/gT9WO94knPg?si=_eJASKNlpzrshCYp" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/l--sUtRAC08?si=Y1A42Igd6h6jxcs2" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Strength training') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/jQ6XPcX-Dfw?si=PUEQsFdlWn7P0no2" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/Cl7E5GoFv6k?si=1FhmGnZP83_PMjj7" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Strength training') && selectedOptions.includes('Advanced') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/BLBTU18pAY0?si=CLNWN8AyBTelZ1jF" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Strength training') && selectedOptions.includes('Advanced') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/OJ1Dbv6YriQ?si=1fW_QTP_rbV-d-82" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/Yam_b28iG9Y?si=Z7DywHtAJGqHNZpG" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Flexibility training') && selectedOptions.includes('Beginner') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/kfjVFQWWiZw?si=yGkZTP2Vq5PpyK0y" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/J_Fi-kOuZns?si=sy2nlLCbh-SgXFTm" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Flexibility training') && selectedOptions.includes('Beginner') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/lDOsaAsIY2I?si=WQnDCbqHUMlnk4Wv" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Flexibility training') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/J-lH5ksxaRg?si=fu8n1tUwpPTzKs45" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/DYgR1J7Hrrc?si=O6M0JNDiq2SXV5ff" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Flexibility training') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/ZvfDryEt5bw?si=UfHPCOWQFJcwUjFz" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Flexibility training') && selectedOptions.includes('Advanced') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/FI51zRzgIe4?si=wWt_ikKiiwc_G19l" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Flexibility training') && selectedOptions.includes('Advanced') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/3cSmYMYOciI?si=2Zu_l_XXFNHDkHYX" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Balance and stability (yoga)') && selectedOptions.includes('Beginner') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/U_jdXFfegKE?si=u_mvD0-B7kVMpWBx" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/laIWV6qJWbk?si=8bqZ_F_QyfnOWXHy" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Balance and stability (yoga)') && selectedOptions.includes('Beginner') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/-rBDxFKJtlE?si=5BKdp4_4VPQGRA39" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Balance and stability (yoga)') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/4-wpPBQZ-as?si=-XRQvvKYxWfZQWoE" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Balance and stability (yoga)') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/1DYH5ud3zHo?si=v1BYlDn6KuqS9L6L" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/pciXaO4wtug?si=zYj61-7nOU3LlFxv" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Balance and stability (yoga)') && selectedOptions.includes('Advanced') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/igmDZv1BCIc?si=h8CfEYVl82g84lFm" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Balance and stability (yoga)') && selectedOptions.includes('Advanced') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/kFhG-ZzLNN4?si=vpfYWlYtIKjh31pN" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/3BGQjGwEr3k?si=x72bZ9kGS3w54xQ_" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    }  else if (selectedOptions.includes('Dance') && selectedOptions.includes('Beginner') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/F4BJst5d9Ac?si=MPggWlKuqQLMk2P0" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/yvRMtSbFSU0?si=OLEAWTMDh7O89UmZ" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Dance') && selectedOptions.includes('Beginner') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/E6lsGDntAEM?si=kXSs5oeYEVGx3W2o" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/d9zSlhGYpvw?si=6MAmCc8W06akjNFo" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Dance') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/pOGQlatXS5E?si=3jzOsnlv5rXVKFB0" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Dance') && selectedOptions.includes('Intermediate') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/kp67VFhbpsQ?si=IJca4l0fBOce4RLl" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Dance') && selectedOptions.includes('Advanced') && selectedOptions.includes('Short(10-15 minutes)')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/4qox-ym6eW4?si=y5qh1c3hBUGldxF1" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    } else if (selectedOptions.includes('Dance') && selectedOptions.includes('Advanced') && selectedOptions.includes('Long')) {
      return (
        <VideoContainer>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/zjJzAXjvbwA?si=CBEX-U7ETaqTz70J" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </VideoContainer>
      );
    }
    else {
      return <p>No specific recommendations based on your selections. Please choose other options.</p>;
    }
  };

  return (
    <Container>
      <Content>
        <LinearProgress variant="determinate" value={progressValue} />
        {!showVideos ? (
          <>
            <h2>Question {currentQuestion + 1} of {questions.length}</h2>
            <FormControl component="fieldset">
              <h3>{questions[currentQuestion].question}</h3>
              <RadioGroup value={value} onChange={handleChange}>
                {questions[currentQuestion].options.map((option, index) => (
                  <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                ))}
              </RadioGroup>
            </FormControl>
            <StyledButton
              variant="contained"
              onClick={handleNext}
              disabled={!value}
            >
              {currentQuestion === questions.length - 1 ? 'Submit' : 'Next'}
            </StyledButton>
          </>
        ) : (
          <div>
            <h3>Recommended Videos:</h3>
            {renderVideos()}
            <RestartButton onClick={handleRestart}>Restart</RestartButton>
          </div>
        )}
      </Content>
    </Container>
  );
};


export default Exercise;

import { useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { tokens } from "../../theme";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VideocamIcon from '@mui/icons-material/Videocam';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ScheduleDialog from "../../components/schedule/ScheduleDialog";

const Item = ({ title, to, icon, selected, setSelected, isCollapsed }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem
            active={selected === title}
            style={{
                color: colors.grey[100],
            }}
            onClick={() => setSelected(title)}
            icon={icon}
            component={<Link to={to} />}
        >
            {!isCollapsed && <Typography>{title}</Typography>}
        </MenuItem>
    );
};

const SidebarComponent = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [row, setRow] = useState({});
    const [showDialog, setShowDialog] = useState(false);
    const location = useLocation();

    const scheduleChange = (e) => {
        setRow({ title: e });
        setShowDialog(true);
    };

    const style = {
        width: isCollapsed ? 'auto' : '100%',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: isCollapsed ? 12 : 16,
        padding: isCollapsed ? '6px' : '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#9c27b0',
        borderColor: '#9c27b0',
        minWidth: 'unset',
    };
    const plusStyle = { fontSize: isCollapsed ? 20 : 30 };
    const show = () => {
        setShowDialog(true);
    };

    const isHomePage = location.pathname === "/" || location.pathname === "/home";

    if (isHomePage) {
        return null;
    }

    return (
        <Box
            sx={{
                "& .ps-sidebar-root": {
                    border: 'none',
                },
                "& .ps-menu-button:hover": {
                    backgroundColor: `${colors.primary[400]} !important`,
                    color: "#868dfb !important",
                },
                "& .ps-menu-button.ps-active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <Sidebar 
                collapsed={isCollapsed}
                width={isCollapsed ? '80px' : '250px'}
                collapsedWidth="80px"
            >
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={<MenuOutlinedIcon />}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    src={`${process.env.PUBLIC_URL}/assets/fearless logo 1.png`}
                                    style={{ 
                                        cursor: "pointer", 
                                        borderRadius: "50%",
                                        width: '100%', 
                                        maxWidth: '200px', 
                                        height: 'auto',
                                    }}
                                />
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                        <Button variant="contained" style={style} onClick={show}>
                            <div style={plusStyle}>+</div>
                            {!isCollapsed && <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Schedule</span>}
                        </Button>
                        <ScheduleDialog open={showDialog} setOpen={setShowDialog} row={row} />

                        <Item
                            title="Calendar"
                            to="/calendar"
                            icon={<CalendarTodayIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            isCollapsed={isCollapsed}
                        />
                        <Item
                            title="Exercise"
                            to="/exercise"
                            icon={<VideocamIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            isCollapsed={isCollapsed}
                        />
                        <Item
                            title="Notification"
                            to="/notification"
                            icon={<NotificationsNoneIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            isCollapsed={isCollapsed}
                        />
                        <Item
                            title="Profile"
                            to="/profileaccount"
                            icon={<AccountCircleIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            isCollapsed={isCollapsed}
                        />
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default SidebarComponent;
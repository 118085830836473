import {useEffect, useRef, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {Box} from "@mui/material";
import SchedulePopover from "../../components/schedule/SchedulePopover";
import {useParams} from "react-router-dom";
import ScheduleDialog from "../../components/schedule/ScheduleDialog";

const Calendar = () => {
    const { id }=useParams();
    const calendarRef = useRef(null);
    const elementRef = useRef(null);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [selectDate, setSelectDate] = useState();
  const names = ['Medication', 'Blood Test', 'Exercise'];
  const handleDateClick = (selected) => {
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();
    setSelectDate(selected);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [row, setRow] = useState({});
  const handleEventClick = (e) => {
    setAnchorEl(e.el);
    setSelectEvent(e);
    setOpen(true);
  };

  const removeItem=(e)=>{
    e.event.remove();
    setOpen(false);
  }
  const detailItem=()=>{
      const event=selectEvent.event;
      const item={
          color: event.backgroundColor,
          description: event.extendedProps?event.extendedProps.description:"",
          end: event.start,
          id: event.id,
          medicineName: event.extendedProps?event.extendedProps.medicineName:"",
          repeat: event.extendedProps?event.extendedProps.repeat:"",
          start: event.start,
          tip: event.extendedProps?event.extendedProps.tip:"",
          title: event.title,
          value: event.extendedProps?event.extendedProps.value:""
      }
      console.log(event)
      setRow(item);
      setShowDialog(true);
  }

  const [selectEvent, setSelectEvent] = useState({});
  let collection = localStorage.getItem("events");
  const type=localStorage.getItem("type")||'All';
  let array=(JSON.parse(collection));
  if(array&&type!=="All"){
    array=array.filter(item=>item.title===type)
  }
  const typeChange=(e)=>{
      localStorage.setItem("type",e.target.value)
      window.location.reload();
  }
  useEffect(() => {
    const simulateEventClick = (date, eventId) => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(new Date(date));
        const event = calendarApi.getEventById(eventId);
        if (event) {
            calendarApi.dispatch('eventClick', event);
            setSelectEvent({event:event});
        }
    };
    if (array&&id&&calendarRef.current) {
        let date=array.filter(item=>parseInt(item.id)===parseInt(id))
        date=date[0].start;
        simulateEventClick(date, id);
    }
}, []);
useEffect(() => {
    const getContent = async () => {
        await new Promise(resolve => setTimeout(resolve, 0));
        if (id&&array&&elementRef.current) {
            let date=array.filter(item=>parseInt(item.id)===parseInt(id))
            date=date[0].start;
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
            const day = ('0' + dateObj.getDate()).slice(-2);
            const formattedDate = `${year}-${month}-${day}`;

            let el=document.querySelectorAll('[data-date="'+formattedDate+'"]')
            el=el[2].querySelector("a")
            setOpen(true);
            setAnchorEl(el)
        }
    };
    getContent();
}, []);
  return (
      <Box m="20px">
          <select onChange={typeChange} value={type}>
              <option value="All">All</option>
              {names.map((name, index) => (
                  <option value={name} key={index}>{name}</option>
              ))}
          </select>
          <div ref={elementRef}>
              <Box display="flex" justifyContent="space-between">
                  <Box flex="1 1 100%" ml="15px">
                      <FullCalendar
                          ref={calendarRef}
                          height="75vh"
                          plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                              listPlugin
                          ]}
                          initialView="timeGridWeek"
                          editable={true}
                          selectable={true}
                          selectMirror={true}
                          dayMaxEvents={true}
                          select={handleDateClick}
                          eventClick={handleEventClick}
                          eventsSet={(events) => setCurrentEvents(events)}
                          initialEvents={array}
                      />
                  </Box>
              </Box>
          </div>
          <SchedulePopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} open={open} setOpen={setOpen}
                           event={selectEvent} remove={removeItem} edit={detailItem}/>
          <ScheduleDialog open={showDialog} setOpen={setShowDialog} row={row} />
      </Box>
  );
};

export default Calendar;

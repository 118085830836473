import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Home from "./scenes/home";
import Exercise from "./scenes/exercise";
import Notification from "./scenes/notification";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Login from "./scenes/home/Login";
import SignUp from "./scenes/home/SignUp";
import ProfileAccount from "./scenes/home/ProfileAccount";
import AdditionalInfoForm from "./scenes/home/AdditionalInfoForm"; 
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  const user=localStorage.getItem('currentUser')
  let flag=false;
  if(user){
    flag=true;
  }
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(flag);
  console.log("isAuthenticated:"+isAuthenticated)
  const location = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/signup" element={<SignUp setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/additional-info" element={<AdditionalInfoForm setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            {/* Protected routes */}
            <Route
              path="/*"
              element={
                isAuthenticated ? (
                  <div className="app">
                    {location.pathname !== "/" && <Sidebar />}
                    <main className="content">
                      <Routes>
                        <Route path="/calendar" element={<Calendar />} />
                        <Route path="/calendar/:id" element={<Calendar />} />
                        <Route path="/exercise" element={<Exercise />} />
                        <Route path="/notification" element={<Notification />} />
                        <Route path="/profileaccount" element={<ProfileAccount />} />
                        <Route path="*" element={<Navigate to="/home" replace />} />
                      </Routes>
                    </main>
                  </div>
                ) : (
                  <Navigate to="/home" replace />
                )
              }
            />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;

import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SubjectIcon from "@mui/icons-material/Subject";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Grid} from "@mui/material";
import TextField from '@mui/material/TextField';
import {useEffect, useState} from "react";

const SchedulePopover=({anchorEl,setAnchorEl, open,setOpen,event,remove,edit})=> {
    const [value, setValue] = useState("");
    useEffect(() => {
        if (event.event) {
           setValue(event.event.extendedProps.value)
        } else {
            setValue("")
        }
    }, [event.event]);
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const removeHandle=()=>{
        remove(event);
    }
    const valueChange=(e)=>{
        setValue(e.target.value)
    }
    const updateChange=(e)=>{
        const id=event.event.id;
        let collection = localStorage.getItem("events");
        if (collection) {
            collection = JSON.parse(collection);
            collection.map((item, index) => {
                if (item.id === parseInt(id)) {
                    item.value = value;
                    if (item.title === 'Blood Test') {
                        if ((item.tip === "After Meal" && parseInt(value) > 180) || (item.tip === "Before Meal" && parseInt(value) > 100)) {
                            item.color = "#e79e9e";
                        } else {
                            item.color = "#e54141";
                        }
                    }
                }
            });
            localStorage.setItem("events", JSON.stringify(collection));
            window.location.reload();
        }
    }
    const id = open ? 'schedule-popover' : undefined;
    return (
        <div>
          <Popover
            id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}>
              <div style={{padding:10}}>
                  <Typography style={{display:"flex",fontSize:24}}>Result</Typography>
                  <TextField id="outlined-basic" value={value} onChange={valueChange} onBlur={updateChange}/>
                  <Typography sx={{ p: 2 }} style={{display:"flex"}}><AccessTimeIcon/>&nbsp;&nbsp;{event.event&&event.event.start.getHours()} {event.event&&event.event.extendedProps.tip}</Typography>
                  <Typography sx={{ p: 2 }} style={{display:"flex"}}><SyncAltIcon/>&nbsp;&nbsp;{event.event&&event.event.extendedProps.repeat}</Typography>
                  <Typography sx={{ p: 2 }} style={{display:"flex"}}><SubjectIcon/>&nbsp;&nbsp;{event.event&&event.event.extendedProps.tip}</Typography>
                  <Grid container>
                      <Grid item xs={6} style={{textAlign:"left",paddingLeft:5}} onClick={edit}><EditIcon/></Grid>
                      <Grid item xs={6} style={{textAlign:"right",paddingRight:5}}><DeleteIcon onClick={removeHandle}/></Grid>
                  </Grid>
              </div>
          </Popover>
        </div>
  );
}

export default SchedulePopover;

import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCBxl9k7fOAOxXYlgZV-8AWjMeXIGpv9HY",
  authDomain: "fearless-3aff9.firebaseapp.com",
  projectId: "fearless-3aff9",
  storageBucket: "fearless-3aff9.appspot.com",
  messagingSenderId: "948128184834",
  appId: "1:948128184834:web:b477d94a53be3a69ba051b",
  measurementId: "G-J12MJFFT0S"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true, message: "Password reset email sent successfully" };
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return { success: false, message: error.message };
  }
};

export { auth, db, sendPasswordReset }